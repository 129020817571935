
import { openWindowWithToken } from "@/utils/util";
import { useRouter, useRoute } from "vue-router";
import roles from "@/types/roles.ts";
import Base from "@/views/layout/Base.vue";
import Pager from "@/views/layout/pager.vue";

import { dicLabel, planBackupStatusText } from "@/network/lookUp.ts";

import {
  useQueryActionHistory,
  useQuery,
  usePlanBackupAction,
} from "@/network/planBackup.ts";
import { defineComponent, inject, onMounted, reactive, ref, toRef } from "vue";
import { useCityRelTown } from "@/network/chinaArea";
import { ElMessageBox } from "element-plus";
import LogHistory from "@/views/shared/logHistory.vue";
export default defineComponent({
  components: {
    Base,
    Pager,
    LogHistory,
  },
  setup() {
    const tabNameArr = ["draft", "cityReview", "provReview", "all"];

    const router = useRouter();
    const route = useRoute();
    const activeName = ref(null);
    const user: any = inject("user");
    const filter = reactive({
      pageNum: 1,
      pageSize: 20,
      status: null,
      keyword: null,
      city: null,
      town: null,
      isRevolution: null,
    });

    const [isLoading, pagerData, query, pager] = useQuery(filter);
    const [isActionLoading, removeFn] = usePlanBackupAction();

    const [dicCity, dicTown] = useCityRelTown(
      toRef(filter, "city"),
      toRef(filter, "town"),
      null
    );

    const showLogHistory = ref(false);
    const [logs, queryHistory] = useQueryActionHistory();
    const doQueryHistory = (id) => {
      showLogHistory.value = true;
      queryHistory(id);
    };

    const tabQuery = () => {
      const index = tabNameArr.findIndex((p) => p == activeName.value);
      filter.status = index == 3 ? null : index;
      query();
    };

    const downloadListExcel = () => {
      openWindowWithToken(`/api/PlanBackup/ExportProjectList`, filter);
    };

    onMounted(() => {
      if (route.query.tab) {
        const index = tabNameArr.findIndex((p) => p == route.query.tab);
        if (index > -1) {
          activeName.value = route.query.tab;
          filter.status = index == 3 ? null : index;
          query();
          return;
        }
      }

      if (user.inRoles(roles.city)) {
        filter.status = 1;
        filter.city = user.city_ID;
      } else if (user.inRoles(roles.prov)) {
        filter.status = 2;
      } else {
        filter.status = 0;
      }
      activeName.value = tabNameArr[filter.status];
      query();
    });

    const allDownload = (id) => {
      openWindowWithToken(`/api/Planbackup/downloadAll/${id}`);
    }

    return {
      allDownload,
      doQueryHistory,
      downloadListExcel,
      removeFn,
      planBackupStatusText,
      activeName,
      showLogHistory,
      roles,
      user,
      dicCity,
      dicTown,
      filter,
      isLoading,
      pagerData,
      tabQuery,
      query,
      pager,
      isActionLoading,
      logs,
    };
  },
});
